import { Langs } from '@/locales';
import { FeedTabSlug } from '@/infra/api/offers/types/shared';
import { isExploreSlug } from './exploreCategories';
import { isExploreCommunitiesSlug } from './exploreCommunities';
import { isShowCommunitiesEnabled } from './featureFlags';

export enum SpecialFeedTab {
  FEATURED = 'FEATURED',
  RECENT = 'RECENT',
  COMMENTED = 'COMMENTED',
  HOTTEST = 'HOTTEST',
}

export enum SpecialFeedTabSlugPtBR {
  HOTTEST = 'mais-quentes',
  RECENT = 'recentes',
  COMMENTED = 'comentadas',
  FEATURED = 'destaques',
}

export enum SpecialFeedTabSlugEnUS {
  HOTTEST = 'hottest',
  RECENT = 'new',
  COMMENTED = 'last-commented',
  FEATURED = 'featured',
}

export enum SearchFeedTab {
  RECENT = 'RECENT',
  COMMENTED = 'COMMENTED',
  HOTTEST = 'HOTTEST',
}

export enum SearchFeedTabSlug {
  HOTTEST = 'mais-quentes',
  RECENT = 'mais-recentes',
  COMMENTED = 'recem-comentadas',
}

export const getTabSlugFromFeedTab = (tab: SpecialFeedTab, locale: Langs) =>
  ({
    [SpecialFeedTab.HOTTEST]: {
      'pt-BR': SpecialFeedTabSlugPtBR.HOTTEST,
      'en-US': SpecialFeedTabSlugEnUS.HOTTEST,
    },
    [SpecialFeedTab.RECENT]: {
      'pt-BR': SpecialFeedTabSlugPtBR.RECENT,
      'en-US': SpecialFeedTabSlugEnUS.RECENT,
    },
    [SpecialFeedTab.COMMENTED]: {
      'pt-BR': SpecialFeedTabSlugPtBR.COMMENTED,
      'en-US': SpecialFeedTabSlugEnUS.COMMENTED,
    },
    [SpecialFeedTab.FEATURED]: {
      'pt-BR': SpecialFeedTabSlugPtBR.FEATURED,
      'en-US': SpecialFeedTabSlugEnUS.FEATURED,
    },
  }[tab][locale]);

const feedTabPtBR = {
  [SpecialFeedTabSlugPtBR.HOTTEST]: SpecialFeedTab.HOTTEST,
  [SpecialFeedTabSlugPtBR.RECENT]: SpecialFeedTab.RECENT,
  [SpecialFeedTabSlugPtBR.COMMENTED]: SpecialFeedTab.COMMENTED,
  [SpecialFeedTabSlugPtBR.FEATURED]: SpecialFeedTab.FEATURED,
};

const feedTabEnUS = {
  [SpecialFeedTabSlugEnUS.HOTTEST]: SpecialFeedTab.HOTTEST,
  [SpecialFeedTabSlugEnUS.RECENT]: SpecialFeedTab.RECENT,
  [SpecialFeedTabSlugEnUS.COMMENTED]: SpecialFeedTab.COMMENTED,
  [SpecialFeedTabSlugEnUS.FEATURED]: SpecialFeedTab.FEATURED,
};

export const getFeedTabFromSlug = (slug: string, locale: Langs) => {
  const feedTabMap = locale === 'pt-BR' ? feedTabPtBR : feedTabEnUS;
  return feedTabMap[slug as keyof typeof feedTabMap];
};

export const getFeedTabFromPath = (currentPath: string, locale: Langs) => {
  if (!currentPath) {
    return SpecialFeedTab.FEATURED;
  }

  const slug = currentPath.split('/')[1];
  const slugWithoutQueryParams = slug.split('?')[0];

  if (!slugWithoutQueryParams) {
    return SpecialFeedTab.FEATURED;
  }

  return getFeedTabFromSlug(slug, locale);
};

export default function isSpecialFeedTab(
  slug: string,
  locale: Langs
): slug is SpecialFeedTabSlugPtBR | SpecialFeedTabSlugEnUS {
  return locale === 'pt-BR'
    ? Object.values(SpecialFeedTabSlugPtBR)
        .map((tab) => tab.toLowerCase())
        .includes(slug) || slug === ''
    : Object.values(SpecialFeedTabSlugEnUS)
        .map((tab) => tab.toLowerCase())
        .includes(slug) || slug === '';
}

export const getSearchFeedTabFromSlug = (slug: SearchFeedTabSlug) =>
  ({
    [SearchFeedTabSlug.HOTTEST]: SearchFeedTab.HOTTEST,
    [SearchFeedTabSlug.RECENT]: SearchFeedTab.RECENT,
    [SearchFeedTabSlug.COMMENTED]: SearchFeedTab.COMMENTED,
  }[slug]);

const titles = {
  'pt-BR': {
    [SpecialFeedTabSlugPtBR.HOTTEST]: 'Mais quentes',
    [SpecialFeedTabSlugPtBR.RECENT]: 'Recentes',
    [SpecialFeedTabSlugPtBR.COMMENTED]: 'Comentadas',
    [SpecialFeedTabSlugPtBR.FEATURED]: 'Destaques',
  },
  'en-US': {
    [SpecialFeedTabSlugPtBR.HOTTEST]: 'Hottest',
    [SpecialFeedTabSlugPtBR.RECENT]: 'New',
    [SpecialFeedTabSlugPtBR.COMMENTED]: 'Last commented',
    [SpecialFeedTabSlugPtBR.FEATURED]: 'Featured',
  },
};

export const getSpecialFeedTitleBySlug = (slug: string, locale: Langs) =>
  titles[locale]?.[slug as keyof (typeof titles)['pt-BR']] || '';

export const isValidFeedSlug = (slug: string, locale: string) => {
  const isShowCommunitiesFlagEnabled = isShowCommunitiesEnabled();

  const isCategoriesOrCommunitiesSlug = isShowCommunitiesFlagEnabled
    ? isExploreCommunitiesSlug
    : isExploreSlug;

  return (
    isSpecialFeedTab(slug, locale as Langs) ||
    isCategoriesOrCommunitiesSlug(slug)
  );
};

const specialSlugEnUStoPtBR = (slug: SpecialFeedTabSlugEnUS) => {
  switch (slug) {
    case SpecialFeedTabSlugEnUS.RECENT:
      return FeedTabSlug.RECENT;
    case SpecialFeedTabSlugEnUS.FEATURED:
      return FeedTabSlug.FEATURED;
    case SpecialFeedTabSlugEnUS.COMMENTED:
      return FeedTabSlug.COMMENTED;
    case SpecialFeedTabSlugEnUS.HOTTEST:
      return FeedTabSlug.HOTTEST;
    default:
      return slug;
  }
};

export const slugEnUStoPtBR = (isCommunity: boolean, slug: string) =>
  specialSlugEnUStoPtBR(slug as SpecialFeedTabSlugEnUS) as unknown as string;
